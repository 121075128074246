// -----------------------------------------------
//    Variables
// -----------------------------------------------

// Colors:
// Should be assigned to "roles", NEVER used directly.
$red        : #a91818;
$red-dark   : #690F0F;
$blue       : #0000ff;
$green      : #14a214;
$black      : #424B57;
$gray       : #D9D9D9;
$gray-medium: #8D8D8D;

//DWM color pallet
$blue-dk      :#759BB6;
$blue-lt      :#A3C7E3;
$blue-xtra-lt :#F1F8FF;
$midnight     :#354B69;
$gray-lt      :#F1F5F7;
$gray-slate   :#748093;
$gray-dk      :#424B57;
$green-dk     :#356569;
$green-lt     :#719193;
$green-teal   :#75B6B1;
$yellow       :#F5A724;
$red          :#C24756;
$bg           :#FCFCFC;


// Color Roles:
// Roles can change more easily than named colors.
$accent-primary       : #26447C;
$accent-secondary     : $blue;
$accent-dk            : $black;
$base-border-color    : $gray;
$base-font-color      : $black;
$base-font-color-light: $gray-medium;
$base-link            : $blue-dk;
$base-link-hover      : $gray-dk;
$base-form-highlight  : $red;
$base-form-color      : $blue-dk;

$background-color     : white;
$foreground-color     : white;


// Fonts:
$em-base:16px;

$base-font-family : "Barlow", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size : em(16);
$base-line-height : 1.5;

$heading-font-family:"Spectral SC", serif;
$heading-font-size : em(16);
$heading-line-height : 1.5;

// Measurements:
$padding           : 16px;
$header-height     : 120px;
$header-height-lg  : 150px;
$sidebar-width     : 400px;
$sidebar-width-lg  : 500px;
$sidebar-width-xlg : 600px;

// Depths:
$background   : 1000;
$middleground : 2000;
$foreground   : 3000;

// Animation Styles:
$interaction-shift: 222ms ease-out;
$layout-shift: 333ms ease-out;
$overlay-shift: 456ms ease-out;
