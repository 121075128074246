/* Usage:
@include type-scale(56, 36, 36);
*/
.base {
  position: relative;
  background: #26447C;
  color: white;
  padding: 32px 0;
  text-align: center; }
  @media only screen and (min-width: 48em) {
    .base {
      padding: 64px 0;
      text-align: left; } }
  .base h2 {
    font-size: 48px;
    font-size: 3rem;
    margin-top: 0; }
    @media only screen and (min-width: 48em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
    @media only screen and (min-width: 60.625em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
    @media only screen and (min-width: 80em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
    @media only screen and (min-width: 48em) {
      .base h2 {
        padding-right: 32px;
        border-right: 1px solid white;
        margin: 0; } }
  .base .contactLinks {
    font-size: 24px;
    font-size: 1.5rem;
    padding-left: 24px; }
    @media only screen and (max-width: 47.9375em) {
      .base .contactLinks {
        padding: 0; } }
  .base a:hover, .base a:active, .base a:focus {
    color: white; }

.content {
  padding-left: 16px;
  padding-right: 16px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .content:after {
    clear: both;
    content: "";
    display: table; }
  @media only screen and (min-width: 30em) {
    .content {
      max-width: 100%; } }
  @media only screen and (min-width: 48em) {
    .content {
      max-width: 100%; } }
  @media only screen and (min-width: 60.625em) {
    .content {
      max-width: 60.625rem; } }
  @media only screen and (min-width: 80em) {
    .content {
      max-width: 80rem; } }
  @media only screen and (min-width: 48em) {
    .content {
      display: flex;
      align-items: center; } }
