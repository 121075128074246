// -----------------------------------------------
//    Typography
// -----------------------------------------------
html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-size: 100%;
  line-height:$base-line-height;
  font-weight: 500;
  color: $base-font-color;
  font-family: $base-font-family;
}

a {
  color:$base-link;
  transition:all 0.3s ease-out;
  text-decoration: none;

  &:hover, &:active, &:focus {
    color:$base-link-hover;
  }
}


// ------------------------------------------------------------
//   Filling in H1-H6 default styling will prep the CMS editor
// ------------------------------------------------------------
.heading {
  font-family: $heading-font-family;
  color: $blue-dk;

  h1 {
    font-weight: 800;
    @include leading-scale(1.2, 1.2, 1.2);
  }
}

h1 {
  // Example Use of Type/Leading Scale for Desktop/Mobile/Tablet (in that order)
  @include type-scale(42, 32, 42);
  @include leading-scale(1.2, 1.4, 1.5);
  font-weight: 200;
}

h2 {
  @include type-scale(28, 22, 24);
  @include leading-scale(1.2, 1.4, 1.5);
  font-weight: 200;
}

h3 {
  @include type-scale(22, 20, 22);
  @include leading-scale(1.2, 1.4, 1.5);
  font-weight: 200;
}

h4 {

}

h5 {

}

h6 {

}

p {
  padding-bottom:15px;
  margin:0;
}

// Leave Lists blank if no custom styling is needed for default.
ul {
}
ol {
}

blockquote {
  border-left:5px solid $base-border-color;
  margin:20px 0;
  padding-left: 15px;
}

// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
