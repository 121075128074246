/* Usage:
@include type-scale(56, 36, 36);
*/
.base {
  position: relative;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../images/creek.jpg");
  background-position: 50% -40vh;
  background-attachment: fixed;
  height: 400px;
  max-height: 60vh; }
  .base:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0.5; }
  @media only screen and (min-width: 48em) {
    .base {
      height: 600px; } }

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .content img {
    width: 480px;
    max-width: 100%;
    height: auto;
    background-color: white;
    margin-bottom: 20px;
    display: block; }
  .content p {
    color: white;
    font-weight: 400;
    margin-bottom: 32px;
    max-width: 768px;
    line-height: 1.4;
    font-size: 20px;
    font-size: 1.25rem; }
  .content:after {
    clear: both;
    content: "";
    display: table; }
  @media only screen and (min-width: 30em) {
    .content {
      max-width: 100%; } }
  @media only screen and (min-width: 48em) {
    .content {
      max-width: 100%; } }
  @media only screen and (min-width: 60.625em) {
    .content {
      max-width: 60.625rem; } }
  @media only screen and (min-width: 80em) {
    .content {
      max-width: 80rem; } }
