/* Scroll-Reveal Example */
.reveal {
  // all revealable elements.
}

.reveal-fade-in {
  // don't style this either, but rather
  // use it as a selector for sub-components
  // in various stages of visibility

  // "fade-in" comes from the <Reveal/> component's
  // 'name' prop, for whcih it is the default value.
  transform: translateY(30px);
  transition: transform 1.25s ease;
  
  &.visible { transform: none; }

  > * { // For example, select every first child
    opacity: 0;
    transform: translateY( 40px );
    transition: all 0.75s ease;
  }

  // this class will be applied to the container
  // when it is on-screen:
  &.visible {
    > * {
      opacity: 1;
      transform: none;
    }
  }

  &:not(.visible) > * {
    opacity: 0;
    transform: translateY(40px);
    transition-delay: 0s !important;
    transition-duration: .2s;
  }
}

.reveal-rise {
  opacity: 0;
  transform-origin: 50% 50%;
  transform: translateY( 40px );
  transition: all 333ms ease;

  &.visible {
    opacity: 1;
    transform: none;
  }
}

// https://www.antimath.info/css/sass-sqrt-function/

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}


.reveal-staggered-rise {
  // MAIN CONTAINER
  transform: translateY(30px);
  transition: transform 1.25s ease;
  &.visible { transform: none; }


  // CHILDREN

  [class*='reveal-step'] { transition: opacity .75s ease, transform .75s ease; }
  &:not(.visible) *[class*='reveal-step'] {
    opacity: 0;
    transform: translateY(40px);
    transition-delay: 0s !important;
    transition-duration: .2s;
  }

  // Stagger (steps 1 - 10)
  @for $i from 1 through 15 {
    .reveal-step-#{$i} { transition-delay: (sqrt($i) * .5s) - .5s; }
  }
}
