/* Usage:
@include type-scale(56, 36, 36);
*/
.base {
  position: fixed;
  z-index: 3000;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  background: white;
  transition: all 333ms ease-out;
  transform: translateY(-100%); }
  .base img {
    display: block;
    height: 48px;
    width: auto; }

.traveling {
  transform: none; }
