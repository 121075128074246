/* Usage:
@include type-scale(56, 36, 36);
*/
.base {
  position: relative;
  background-color: #26447C;
  color: white;
  padding: 32px 0; }
  @media only screen and (min-width: 48em) {
    .base {
      padding: 64px 0; } }
  .base h2 {
    font-size: 48px;
    font-size: 3rem;
    margin-top: 0; }
    @media only screen and (min-width: 48em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
    @media only screen and (min-width: 60.625em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
    @media only screen and (min-width: 80em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
  .base h3 {
    text-transform: uppercase;
    letter-spacing: 0.05em; }

.content {
  padding-left: 16px;
  padding-right: 16px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .content:after {
    clear: both;
    content: "";
    display: table; }
  @media only screen and (min-width: 30em) {
    .content {
      max-width: 100%; } }
  @media only screen and (min-width: 48em) {
    .content {
      max-width: 100%; } }
  @media only screen and (min-width: 60.625em) {
    .content {
      max-width: 60.625rem; } }
  @media only screen and (min-width: 80em) {
    .content {
      max-width: 80rem; } }

@media only screen and (min-width: 48em) {
  .columns {
    display: flex; }
    .columns > div {
      width: 50%;
      flex-shrink: 0;
      padding-right: 32px; }
      .columns > div:last-child {
        padding-right: 0;
        padding-left: 32px; } }
