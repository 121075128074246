/* Usage:
@include type-scale(56, 36, 36);
*/
.base {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .base:after {
    clear: both;
    content: "";
    display: table; }
  @media only screen and (min-width: 30em) {
    .base {
      max-width: 100%; } }
  @media only screen and (min-width: 48em) {
    .base {
      max-width: 100%; } }
  @media only screen and (min-width: 60.625em) {
    .base {
      max-width: 60.625rem; } }
  @media only screen and (min-width: 80em) {
    .base {
      max-width: 80rem; } }
  @media only screen and (min-width: 48em) {
    .base {
      display: flex; } }
  .base h2 {
    font-size: 48px;
    font-size: 3rem;
    margin-top: 0; }
    @media only screen and (min-width: 48em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
    @media only screen and (min-width: 60.625em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
    @media only screen and (min-width: 80em) {
      .base h2 {
        font-size: 64px;
        font-size: 4rem; } }
  .base p {
    font-weight: 400; }

.tile {
  padding: 32px; }
  @media only screen and (min-width: 48em) {
    .tile {
      width: 50%;
      flex-shrink: 0 padding 48px; } }

.mobilePortrait {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 16px;
  border: 1px solid #d8ced5; }
  @media only screen and (min-width: 48em) {
    .mobilePortrait {
      display: none; } }

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 64px solid transparent; }
  @media only screen and (max-width: 47.9375em) {
    .left {
      display: none; } }

@media only screen and (min-width: 48em) {
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center; } }
